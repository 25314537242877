import { createStore } from 'zustand/vanilla';
import fetchAPI from 'utils/api';

export type GlobalState = {
  data: any;
};

// Create intersectional type for store
export type GlobalStore = GlobalState & GlobalActions;

export type GlobalStoreApi = ReturnType<typeof createGlobalStore>;

// Define store actions
export type GlobalActions = {
  fetchData: (url: string, params: {}) => Promise<void>;
};

// Factory function to create the Zustand store
export const createGlobalStore = (initState: GlobalState) => {
  return createStore<GlobalStore>()((set) => ({
    // Pass non-fetched state for initialization
    ...initState,

    // Async action to fetch data to be called from server wrapper function
    fetchData: async (path: string, params: {}) => {
      try {
        const response = await fetchAPI(path, params);
        const data = await response;
        set({ data });
      } catch (error: any) {
        console.error('Failed to fetch hompage data');
      }
    },
  }));
};
