import { Context, createContext } from 'react';

interface FeatureFlagType {
  companyMilestones: boolean;
  companyReviews: boolean;
  hotjar: boolean;
  userback: boolean;
  datadog: boolean;
  blogDiscussions: boolean;
  heroVideo: boolean;
  bustAPICache: boolean;
}

export const FeatureFlags: FeatureFlagType = {
  companyMilestones: !!process.env.EXPERIMENTAL_COMPANYMILESTONES,
  companyReviews: !!process.env.EXPERIMENTAL_COMPANYREVIEWS,
  hotjar: !!process.env.EXPERIMENTAL_HOTJAR,
  userback: !!process.env.EXPERIMENTAL_USERBACK,
  datadog: !!process.env.EXPERIMENTAL_DATADOG,
  blogDiscussions: !!process.env.EXPERIMENTAL_BLOGDISCUSSIONS,
  bustAPICache: process.env.BUST_API_CACHE === 'true' ? true : false,
  heroVideo:
    process.env?.NEXT_PUBLIC_EXPERIMENTAL_HERO_VIDEO === 'true' ? true : false,
};
