'use client';
import { useStore } from 'zustand';
import { type ReactNode, createContext, useRef, useContext } from 'react';
import {
  GlobalStore,
  createGlobalStore,
  GlobalStoreApi,
} from 'stores/global/global-store';

export const GlobalStoreContext = createContext<GlobalStoreApi | undefined>(
  undefined
);

export interface GlobalStoreProviderProps {
  children: ReactNode;
  initialData: Omit<GlobalStore, 'fetchData'>; // Initial state including server-fetched data but omit function
}

// Custom Context Provider for sending store via context
export const GlobalStoreProvider = ({
  children,
  initialData,
}: GlobalStoreProviderProps) => {
  const storeRef = useRef<GlobalStoreApi>();

  if (!storeRef.current) {
    storeRef.current = createGlobalStore(initialData);
  }

  return (
    <GlobalStoreContext.Provider value={storeRef.current}>
      {children}
    </GlobalStoreContext.Provider>
  );
};

// Custom hook to access Zustand store in client components
export const useGlobalStore = <T,>(selector: (state: GlobalStore) => T): T => {
  const store = useContext(GlobalStoreContext);
  if (!store) {
    throw new Error('useGlobalStore must be used within GlobalStoreProvider');
  }
  return useStore(store, selector);
};
