import { FeatureFlags } from 'contexts/featureFlags';
import qs from 'qs';

const fetchAPI = async (path: string, params = {}) => {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': FeatureFlags.bustAPICache ? 'no-cache' : '',
      },
    };
    const queryString = qs.stringify(params),
      requestUrl = `${path}${queryString ? `?${queryString}` : ''}`,
      response = await fetch(requestUrl, options),
      jsonResponse = await response.json();

    return jsonResponse.data.attributes;
  } catch (error) {
    console.error(error);
  }
};

export default fetchAPI;
