'use client';

import { CardProps } from '@/types';
import React, { useMemo } from 'react';

export const Cards: React.FC<CardProps<HTMLDivElement>> = ({
  variant,
  ...props
}) => {
  const variants = useMemo(() => {
    const getVariant = (variant: string) => {
      switch (variant) {
        case 'default':
          return 'bg-white text-gray-700';
        case 'primary':
          return 'bg-blue-500 text-gray-100';
        case 'secondary':
          return 'bg-gray-500 text-gray-100';
        case 'warning':
          return 'bg-yellow-500 text-gray-700';
        case 'danger':
          return 'bg-red-500 text-gray-100';
        default:
          return '';
      }
    };
    return getVariant(variant || '');
  }, [variant]);

  return <div {...props} className={variants + ' ' + props.className}></div>;
};

export const CardHeader: React.FC<CardProps<Element>> = ({
  variant,
  ...props
}) => {
  return <div {...props}></div>;
};

export const CardBody: React.FC<CardProps<Element>> = ({
  variant,
  ...props
}) => {
  return <div {...props}></div>;
};

export const CardFooter: React.FC<CardProps<Element>> = ({
  variant,
  ...props
}) => {
  return <div {...props}></div>;
};
